import React, { useState, useEffect } from "react";
import styled from "styled-components";
import htmlSerializer from "../SpaceConntainer/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import { color, typography } from "../../shared/style";
import Fade from "react-reveal/Fade";
import { isMobile } from "../../hooks/checkMobile";
import { LocationMap } from "../../components/Map/LocationMap";
import PropTypes from "prop-types";
import { Text } from "../../components/Text/Text";

export const Globally = ({ data }) => {
  const { primary, items } = data || {};
  const [geos, setGeos] = useState([]);
  useEffect(() => {
    const geoList = items.map((item) => {
      const ite = item?.location?.document?.data;
      return {
        geolocation: ite?.geo_location,
        city: ite?.city,
        country: ite?.country,
        email: ite?.body5[0]?.primary?.email,
        phone: ite?.body5[0]?.primary?.phone_number,
      };
    });
    setGeos(geoList);
  }, [items]);

  if (!primary || !items) return null;
  return (
    <WrapperSection>
      <WrapperGeneral>
        <Content>
          <Title
            font={typography.type.code}
            tag="h3"
            color={color.primary.blue}
            capital="uppercase"
            size={typography.size.code}
            weight={typography.weight.regular1}
            lineHeight={90}
            align="center"
          >
            <RichText
              render={primary?.title.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          <Description
            font={typography.type.primary}
            color={color.netraul.black80}
            size={typography.size.m1}
            weight={typography.weight.bold}
            lineHeight={28}
            align="center"
          >
            <RichText
              render={primary?.description?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Description>
        </Content>
        <Fade bottom disabled={isMobile} distance="10px">
          <Location>
            <LocationMap geolocations={geos} page="about" />
          </Location>
        </Fade>
      </WrapperGeneral>
    </WrapperSection>
  );
};
const WrapperSection = styled.div`
  background: ${color.primary.white};
  max-width: 1500px;
  width: 100%;
`;
const WrapperGeneral = styled.div`
  padding-top: 130px;
  margin: 0 auto;
  text-align: center;
  width: 72%;
  @media (max-width: 1499px) {
    padding-top: 80px;
    width: 91%;
    padding-bottom: 10px;
  }
  @media (max-width: 650px) {
    padding-top: 80px;
    width: 91%;
    padding-bottom: 10px;
  }
`;
const Content = styled.div`
  padding-bottom: 65px;
  display: flex;
    flex-direction: column;
  @media (max-width: 650px) {
    padding-bottom: 25px;
  }
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  padding-bottom: 14px;
  @media (max-width: 650px) {
    line-height: 62px !important;
    font-size: 62px !important;
  }
`;
const Description = styled(Text)`
  @media (max-width: 650px) {
    div {
      padding-top: 30px;
    }
  }
`;
const Location = styled.div`
`;

Globally.propTypes = {
  geolocations: PropTypes.arrayOf(
    PropTypes.shape({
      geolocation: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
      label: PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
      }),
      link: PropTypes.shape({
        link_type: PropTypes.string,
      }),
    })
  ),
};

Globally.defaultProps = {
  geolocations: [
    {
      geolocation: {
        latitude: 16,
        longitude: 108.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da Nang Hello",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 45.5604128,
        longitude: 77.6697583,
      },
      label: {
        type: "heading1",
        text: "An do",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 34.7678813,
        longitude: 19.799,
      },
      label: {
        type: "heading1",
        text: "Chau phi",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 22.01648534731439,
        longitude: -79.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da nang",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 42.91648534731439,
        longitude: 19.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da nang",
      },
      link: {
        url: "Any",
      },
    },
    {
      geolocation: {
        latitude: 45.31648534731439,
        longitude: 108.82836914062501,
      },
      label: {
        type: "heading1",
        text: "Da nang",
      },
      link: {
        url: "Any",
      },
    },
  ],
};
