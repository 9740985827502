import { useStaticQuery, graphql } from "gatsby"

export const useQueryAboutUs = () => {
  const { allPrismicAboutUs } = useStaticQuery(
    graphql`
      query QueryAboutUs {
        allPrismicAboutUs {
          nodes {
            data {
              body6 {
                ... on PrismicAboutUsBody6Banner {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    banner1 {
                      url
                      alt
                    }
                  }
                }
              }
              body {
                ... on PrismicAboutUsBodyStory {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    image {
                      alt
                      url
                    }
                  }
                }
              }
              body1 {
                ... on PrismicAboutUsBody1Mission {
                  primary {
                    image_title {
                      url
                      alt
                    }
                    description {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                  }
                }
              }
              body2 {
                ... on PrismicAboutUsBody2DshSCoreValues {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    icon {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body3 {
                ... on PrismicAboutUsBody3GlobalMap {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    location {
                      document {
                        ... on PrismicLocationSpace {
                          data {
                            city {
                              html
                              text
                              raw
                            }
                            country {
                              html
                              text
                              raw
                            }
                            geo_location {
                              latitude
                              longitude
                            }
                            body5 {
                              ... on PrismicLocationSpaceBody5AddressAndContacts {
                                primary {
                                  phone_number {
                                    html
                                    text
                                    raw
                                  }
                                  email {
                                    html
                                    text
                                    raw
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              body4 {
                ... on PrismicAboutUsBody4Connect {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    facebook {
                      url
                      link_type
                      type
                    }
                    youtube {
                      url
                      type
                      link_type
                    }
                    linkedin {
                      url
                      type
                      link_type
                    }
                    instagram {
                      url
                      link_type
                      type
                    }
                  }
                }
              }
              body7 {
                ... on PrismicAboutUsBody7Marketing {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    button_label {
                      html
                      text
                      raw
                    }
                    button_link {
                      url
                      type
                      link_type
                    }
                  }
                }
              }
              body8 {
                ... on PrismicAboutUsBody8MeetDshTeam {
                  items {
                    team_member {
                      document {
                        ... on PrismicTeamMembers {
                          data {
                            name {
                              html
                              text
                              raw
                            }
                            position {
                              html
                              text
                              raw
                            }
                            avatar {
                              alt
                              url
                            }
                            contacts {
                              contact_link {
                                url
                                type
                                link_type
                              }
                              contact_label
                            }
                          }
                        }
                      }
                    }
                  }
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    content {
                      html
                      text
                      raw
                    }
                    international_founder {
                      document {
                        ... on PrismicTeamMembers {
                          data {
                            name {
                              html
                              text
                              raw
                            }
                            position {
                              html
                              text
                              raw
                            }
                            avatar {
                              alt
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              body11 {
                ... on PrismicAboutUsBody11StoriesAndNews {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    logo {
                      url
                      alt
                    }
                    article {
                      url
                      type
                      link_type
                    }
                  }
                }
              }
              body9 {
                ... on PrismicAboutUsBody9BlockButton {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    button_label {
                      html
                      text
                      raw
                    }
                    button_link {
                      url
                      type
                      link_type
                    }
                  }
                }
              }
              body10 {
                ... on PrismicAboutUsBody10GeneralCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    robot {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                    googlebot {
                      html
                      text
                      raw
                    }
                    favicon {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                    bingbot {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicAboutUsBody10OpenGraph {
                  primary {
                    og_url {
                      url
                      type
                      link_type
                    }
                    og_type {
                      html
                      text
                      raw
                    }
                    og_title {
                      html
                      text
                      raw
                    }
                    og_site_name {
                      html
                      text
                      raw
                    }
                    og_locale {
                      html
                      text
                      raw
                    }
                    og_image_width {
                      html
                      text
                      raw
                    }
                    og_image_height {
                      html
                      text
                      raw
                    }
                    og_image {
                      alt
                      url
                    }
                    og_description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicAboutUsBody10TwitterCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    image {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicAboutUsBody10PinterestProductPin {
                  slice_type
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    price
                    currency
                    availability
                  }
                }
              }
              body12 {
                ... on PrismicAboutUsBody12Investor {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    image {
                      alt
                      url
                    }
                    button_link {
                      url
                      type
                      link_type
                    }
                    button_label {
                      html
                      text
                      raw
                    }
                  }
                }
              }
            }
          }
        }
      }
      `
  )
  // allInstagramContent (limit: 20) {
  //   edges {
  //     node {
  //       caption
  //       media_url
  //       thumbnail_url
  //       media_type
  //       localFile {
  //         childImageSharp {
  //           gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
  //         }
  //       }
  //       album {
  //         localFile {
  //           childImageSharp {
  //             gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
  //           }
  //         }
  //       }
  //     }
  //   }
  //   totalCount
  // }
  return {
    allPrismicAboutUs,
    allInstagramContent: [],
  }
}