import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";

export const AboutTeam = ({ data }) => {
  if (!data) return null;
  return (
    <WrapperSection id="team">
      <WrapperGeneral>
        <Title
          font={typography.type.code}
          tag="h3"
          color={color.primary.blue}
          capital="uppercase"
          size={typography.size.code}
          weight={typography.weight.regular}
          lineHeight={90}
          align="center"
        >
          <RichText render={data?.title?.raw} htmlSerializer={htmlSerializer} />
        </Title>
        <Content>
          <BoxLeft>
            <BoxImage>
              <Image
                src={data?.international_founder?.document?.data?.avatar?.url}
              />
            </BoxImage>
            <BoxInfo>
              <Name
                font={typography.type.code}
                tag="h3"
                color={color.netraul.black100}
                capital="uppercase"
                size={typography.size.l1}
                weight={typography.weight.regular}
                lineHeight={36}
                align="left"
              >
                {data?.international_founder?.document?.data?.name?.text}
              </Name>
              <Position
                font={typography.type.primary}
                tag="h3"
                color={color.netraul.black80}
                size={typography.size.s4}
                weight={typography.weight.regular}
                lineHeight={28}
                align="left"
              >
                {data?.international_founder?.document?.data?.position?.text}
              </Position>
            </BoxInfo>
          </BoxLeft>
          <WrapperBoxRight>
            <BoxRight>
              <ContentRight>
                <TitleRight
                  font={typography.type.code}
                  color={color.primary.white}
                  capital="uppercase"
                  size={typography.size.l3}
                  weight={typography.weight.regular1}
                  lineHeight={56}
                  align="left"
                >
                  <RichText
                    render={data?.description?.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </TitleRight>
                <Description
                  font={typography.type.primary}
                  color={color.primary.white}
                  size={typography.size.s3}
                  weight={typography.weight.regular}
                  lineHeight={26}
                  align="left"
                >
                  <RichText
                    render={data?.content?.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </Description>
              </ContentRight>
            </BoxRight>
          </WrapperBoxRight>
        </Content>
      </WrapperGeneral>
    </WrapperSection>
  );
};
const WrapperSection = styled.div`
  display: flex;
  justify-content: center;
  background: ${color.primary.white};
  max-width: 1500px;
`;
const WrapperBoxRight = styled.div`
  margin-left: -11%;
  margin-top: 55px;
  height: fit-content;
  @media (max-width: 1024px) {
    margin-left: 0%;
    margin-top: -60px;
  }
  @media (max-width: 650px) {
    margin-top: -60px;
  }
  @media (max-width: 375px) {
    margin-top: -60px;
  }
`;
const BoxRight = styled.div`
  background: ${color.secondary.danube};
`;
const WrapperGeneral = styled.div`
  /* width: 81%; */
  max-width: 1240px;
  padding-top: 150px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1499px) {
    max-width: unset;
  }
  @media (max-width: 1300px) {
    /* width: 91%; */
  }
  @media (max-width: 1024px) {
    padding-top: 80px;
    padding-bottom: 0px;
  }
`;
const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    padding-left: 20px;
    margin-bottom: 80px;
  }
  @media (max-width: 650px) {
    padding-left: 20px;
    margin-bottom: 80px;
    display: flex;
  }
`;

const BoxImage = styled.div`
  width: 512px;
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 650px) {
  }
  @media (max-width: 375px) {
    width: 178px;
    min-width: 178px;
    max-width: 178px;
    height: 203px;
  }
`;
const Content = styled.div`
  padding-top: 70px;
  height: 75.4%;
  display: flex;
  @media (max-width: 1499px) {
    padding: 70px 40px 0;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
    padding: 40px 40px 0;
  }
  @media (max-width: 992px) {
    padding: 40px 30px;
  }
  @media (max-width: 650px) {
    padding: 40px 0 0;
    margin: 0 20px;
  }
`;
const Title = styled(Text)`
  /* width: 25%; */
  letter-spacing: -0.015em;
  @media (max-width: 650px) {
    font-size: ${typography.size.l4}px !important;
    line-height: 62px !important;
    font-weight: ${typography.weight.regular1};
    text-align: left;
    div {
      padding-left: 2.5%;
    }
  }
`;
const TitleRight = styled(Text)`
  letter-spacing: -0.015em;
  margin-bottom: 20px;
  @media (max-width: 650px) {
    font-size: ${typography.size.l4}px !important;
    line-height: 62px !important;
    font-weight: ${typography.weight.regular1};
    text-align: left;
  }
`;

const Description = styled(Text)`
  div {
    padding-top: 24px;
  }
`;
const Name = styled(Text)`
  padding: 32px 0 12px;
  @media (max-width: 650px) {
    padding: 0px;

    font-size: 30px !important;
    font-weight: ${typography.weight.regular1};
  }
`;
const Position = styled(Text)`
  @media (max-width: 650px) {
    font-size: ${typography.size.s3}px !important;
    line-height: 26px !important;
    padding-top: 8px;
  }
`;
const Image = styled.img`
  height: 100%;
  object-fit: cover;
`;
const ContentRight = styled.div`
  padding: 80px 61px 80px 230px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1366px) {
    padding: 80px 61px 80px 150px;
  }
  @media (max-width: 1024px) {
    padding: 95px 30px 64px;
  }
  @media (max-width: 650px) {
    padding: 95px 20px 40px;
  }
`;
const BoxLeft = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    flex-direction: row;
    width: auto;
    padding-left: 20px;
  }
`;
