import scrollTo from "gatsby-plugin-smoothscroll";
import React, { useMemo } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { color, typography } from "../../shared/style";
import { AboutTeam } from "./AboutTeam";
import { Banner } from "./Banner";
import { DraperNFTs } from "./DraperNFTs";
import { Globally } from "./Globally";
import { House } from "./House";
import { Media } from "./Media";
import { Member } from "./Member";
import { OurGoal } from "./OurGoal";
import { OurStory } from "./OurStory";
import { Partner } from "./Partner";
import { Social } from "./Social";
import { Values } from "./Values";

export const AboutUsContainer = ({ data, instagramData }) => {
  const photos = useMemo(() => {
    return instagramData?.edges?.map((edge) => edge.node);
  }, [instagramData]);

  return (
    <WrapperLayout>
      <Banner data={data?.body6[0]?.primary} />
      <CheckMobile>
        <WrapperSection>
          <ButtonNav onClick={() => scrollTo("#mission")}>Mission</ButtonNav>
          <ButtonNav onClick={() => scrollTo("#team")}>Team</ButtonNav>
          <ButtonNav onClick={() => scrollTo("#media")}>Media</ButtonNav>
        </WrapperSection>
      </CheckMobile>
      <OurStory
        data={data?.body[0] === undefined ? {} : data?.body[0]?.primary}
      />
      <DraperNFTs data={data?.body12[0] === undefined ? null : data?.body12[0]?.primary}/>
      <OurGoal
        data={data?.body1[0] === undefined ? {} : data?.body1[0]?.primary}
      />
      <Values data={data?.body2[0] === undefined ? {} : data?.body2[0]} />
      {data?.body3[0] && (
        <Globally data={data?.body3[0] === undefined ? {} : data?.body3[0]} />
      )}
      <Partner data={data?.body7[0] ? {} : data?.body7[0]} />
      <AboutTeam
        data={data?.body8[0] === undefined ? {} : data?.body8[0]?.primary}
      />
      {data?.body8[0] && (
        <Member data={data?.body8[0] === undefined ? {} : data?.body8[0]} />
      )}
      <Media
        data={data}
        stories_and_news={data?.body11[0] === undefined ? {} : data?.body11[0]}
      />
      <Social data={data?.body4[0] === undefined ? {} : data?.body4[0]} />
      {photos && photos.length && (
        <House
          photos={photos}
          data={data?.body[0] === undefined ? {} : data?.body[0]}
          total={instagramData?.totalCount}
        />
      )}
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  margin-bottom: 100px;
  overflow: hidden;
  margin: 0 auto;
  /* background-color: ${color.primary.blue10}; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CheckMobile = styled.div`
  display: none;
  @media (max-width: 650px) {
    display: block;
    width: 100%;
  }
`;
const WrapperSection = styled.div`
  height: 80px;
  display: grid;
  grid-template-columns: auto auto auto;
  box-sizing: border-box;
  @media (max-width: 650px) {
  }
`;
const ButtonNav = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.primary.white};
  border-top: none;
  border-radius: 0;
  border: 1px solid ${color.netraul.black10};
  box-shadow: 1px 0px 0px #d2d9e2;
  font-weight: ${typography.weight.bold};
  font-size: ${typography.size.s2}px;
  color: ${color.netraul.black70};
  line-height: 22px;
  box-sizing: border-box;
  padding: 0;
  &:hover {
    border-top: none;
  }
`;
