import React from "react";
import styled from "styled-components";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
// import banner from "../../images/our-story-banner.png";
import { color, typography } from "../../shared/style";
import { Text } from "../../components/Text/Text";

export const OurStory = ({ data }) => {
  if (!data) return null;
  return (
    <WrapperSection>
      <WrapperGeneral>
        <Content>
          <Title
            font={typography.type.code}
            tag="h3"
            color={color.primary.blue}
            capital="uppercase"
            size={typography.size.code}
            weight={typography.weight.regular1}
            lineHeight={90}
            align="left"
          >
            <RichText
              render={data?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          <ImageMobile src={data?.image?.url} />
          <Description
            font={typography.type.primary}
            color={color.netraul.black80}
            size={typography.size.s3}
            weight={typography.weight.regular}
            lineHeight={26}
            align="left"
          >
            <RichText
              render={data?.description?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Description>
        </Content>
        <Image src={data?.image?.url} />
      </WrapperGeneral>
    </WrapperSection>
  );
};
const WrapperSection = styled.div`
  display: flex;
  align-items: center;
  padding: 0 131px;
  max-width: 1500px;
  background: ${color.primary.white};
  @media (max-width: 1499px) {
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    height: auto;
    padding: 0 20px;
  }
`;
const WrapperGeneral = styled.div`
  padding: 150px 0;
  /* width: 81%; */
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    padding: 100px 0;
  }
  @media (max-width: 992px) {
    padding: 90px 0;
    flex-direction: column;
    /* width: 91%; */
  }
  @media (max-width: 650px) {
    flex-direction: column;
    /* width: 91%; */
    padding: 60px 0 100px 0;
  }
`;
const Content = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-right: 120px;
  @media (max-width: 1200px) {
    margin-top: 0;
    gap: 0;
  }
  @media (max-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    padding-right: 0;
  }
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`;
const Description = styled(Text)`
  div {
    margin-top: 32px;
    width: 81%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`;
const Image = styled.img`
  display: block;
  height: fit-content;
  flex: 0 0 50%;
  max-width: 50%;
  /* @media (min-width: 1400px) {
    width: 603px;
    height: 603px;
  } */
  @media (max-width: 1400px) {
    width: 100%;
  }
  box-sizing: border-box;
  @media (max-width: 992px) {
    display: none;
  }
`;
const ImageMobile = styled.img`
  display: none;
  width: 100%;
  margin-bottom: 32px;
  @media (max-width: 992px) {
    display: block;
    padding-top: 40px;
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
