import React from "react";
import styled from "styled-components";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import { color, typography } from "../../shared/style";
import { Text } from "../../components/Text/Text";
// import image from "../../images/forbes.svg";
import icon from "../../images/kit.svg";
import { Button } from "../../components/Button/Button";
import { redirectPage } from "../../hooks/redirect";

export const Media = ({ data, stories_and_news }) => {
  const block_button = data?.body9?.find(i => i.__typename === "PrismicAboutUsBody9BlockButton")?.primary;
  return (
    <>
      {stories_and_news.items.length !==0 && (
        <WrapperSection id="media">
        <WrapperGeneral>
          <Title
            font={typography.type.code}
            tag="h3"
            color={color.primary.blue}
            capital="uppercase"
            size={typography.size.code}
            weight={typography.weight.regular}
            lineHeight={90}
            align="center"
          >
            <RichText
              render={stories_and_news?.primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          <WrapperGeneralTop>
            {stories_and_news?.items?.map((item, i) => (
              <BoxImage 
              key={i} 
              onClick={()=> 
                redirectPage({
                  url: item?.article?.url,
                  type: item?.article?.type,
                  link_type: item?.article?.link_type
                })}
              >
                <Image src={item.logo?.url} />
              </BoxImage>
            ))}
          </WrapperGeneralTop>
        </WrapperGeneral>
      </WrapperSection>
      )}
      {block_button && (
        <WrapperSectionBlock>
        <WrapperGeneralBlock>
          <WrapperGeneralBottom>
            <Content>
              <Title
                font={typography.type.code}
                tag="h3"
                color={color.primary.white}
                capital="uppercase"
                size={typography.size.code}
                weight={typography.weight.regular1}
                lineHeight={90}
                align="left"
              >
                <RichText
                  render={block_button?.title?.raw}
                  htmlSerializer={htmlSerializer}
                />
              </Title>
              <BoxDescription>
                <Description
                  font={typography.type.primary}
                  color={color.primary.white}
                  size={typography.size.s3}
                  weight={typography.weight.regular}
                  lineHeight={26}
                  align="left"
                >
                  <RichText
                    render={block_button?.description?.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </Description>
              </BoxDescription>
              <ButtonBorder>
                <ButtonGet onClick={() =>
                  redirectPage({
                    url: block_button?.button_link?.url,
                    type: block_button?.button_link?.type,
                    link_type: block_button?.button_link?.link_type
                  })
                }>
                  <Icon src={icon} />
                  <ContentButton
                    font={typography.type.primary}
                    color={color.netraul.black100}
                    size={typography.size.m1}
                    weight={typography.weight.bold}
                    lineHeight={28}
                    align="center"
                  >
                    {block_button?.button_label?.text}
                  </ContentButton>
                </ButtonGet>
              </ButtonBorder>
            </Content>
          </WrapperGeneralBottom>
        </WrapperGeneralBlock>
      </WrapperSectionBlock>
      )}
    </>
  );
};

const Content = styled.div`
  padding: 56px 78px 58px 91px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    padding: 0px;
    flex-direction: column;
  }
`;

const WrapperSection = styled.div`
  display: flex;
  justify-content: center;
  background: ${color.primary.blue10};
  max-width: 1500px;
  width: 100%;
  @media (max-width: 650px) {
    height: auto;
  }
`;

const WrapperGeneral = styled.div`
  /* width: 100%; */
  max-width: 1240px;
    width: 100%;
  padding-top: 120px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1499px) {
    max-width: unset;
  }
  @media (max-width: 1024px) {
    /* width: 100%; */
    width: 100%;
    margin: 0 40px;
    padding-top: 80px;
  }
  @media (max-width: 992px) {
    margin: 0 30px;
  }
  @media (max-width: 650px) {
    /* width: 100%; */
    width: inherit;
    margin: 0 20px;
    padding-bottom: 60px;
  }
`;

const WrapperSectionBlock = styled.div`
/* display: none !important; */
  display: flex;
  justify-content: center;
  max-width: 1240px;
  width: 100%;
  padding: 0 131px;
  background: ${color.primary.white};
  @media (max-width: 1200px) {
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
    background: linear-gradient(to bottom, #e9ecf1 50%, #fff 50%);
  }
`;
const WrapperGeneralBlock = styled.div`
  width: 100%;
`;
const WrapperGeneralTop = styled.div`
  padding-top: 60px;
  box-sizing: border-box;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 19px;
  grid-row-gap: 22px;
  width: 100%;
  @media (max-width: 1366px) and (min-width: 1024px){
    width: inherit;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 60px 40px;
  }
  @media (max-width: 650px) {
    padding-top: 32px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 19px;
    grid-row-gap: 20px;
  }
`;
const WrapperGeneralBottom = styled.div`
  margin-top: 80px;
  background-color: ${color.primary.blue};
  @media (max-width: 1024px) and (min-width: 650px) {
    padding: 60px 20px 50px;
  }
  @media (max-width: 650px) {
    padding: 60px 20px 50px;
    height: auto;
    margin-top: 0;
  }
`;
const Image = styled.img`
  object-fit: contain;
`;
const Icon = styled.img`
  max-width: 28px;
  max-height: 28px;
  @media (max-width: 650px) {
    width: 20px;
    height: 20px;
  }
`;
const ContentButton = styled(Text)`
  width: 100%;
  text-align: center;
  padding-top: 9px;
  cursor: pointer;
  @media (max-width: 650px) {
    font-size: ${typography.size.s2}px !important;
    line-height: 14px !important;
  }
`;
const ButtonBorder = styled.div`
  margin: auto 0;
  position: relative;
  @media (max-width: 1024px) and (min-width: 650px) {
    margin: 0 auto;
  }
  @media (max-width: 650px) {
    margin: 0 auto;
    width: 131px;
    height: 131px;
  }
  ::before {
    position: absolute;
    content: " ";
    top: -21px;
    left: -21px;
    background-color: #b5973e;
    width: 216px;
    height: 216px;
    border-radius: 100%;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    opacity: 0.1;
    -webkit-animation: pulse 1s ease-out;
    animation: pulse 1.5s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    @media (max-width: 650px) {
      width: 160px;
      height: 160px;
      top: -15px;
      left: -15px;
    }
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(0);
      opacity: 0;
    }
    25% {
      -webkit-transform: scale(0.3);
      opacity: 1;
    }
    50% {
      -webkit-transform: scale(0.6);
      opacity: 0.6;
    }
    75% {
      -webkit-transform: scale(0.9);
      opacity: 0.3;
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    25% {
      transform: scale(0.3);
      opacity: 1;
    }
    50% {
      transform: scale(0.6);
      opacity: 0.6;
    }
    75% {
      transform: scale(0.9);
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
`;
const ButtonGet = styled(Button)`
  width: 176px;
  height: 176px;
  background: #ffcc00;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  z-index: 1;
  padding: 20px 22px;
  :hover {
    background: yellow;
  }
  @media (max-width: 650px) {
    width: 131px;
    height: 131px;
  }
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const BoxImage = styled.div`
  cursor: pointer;
  background: ${color.primary.white};
  display: flex;
  max-width: 295px;
  max-height: 184px;
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 25px 55px rgb(0 0 0 / 22%);
  }
  img {
    width: 55%;
    padding: 14.25% 23.055%;
  }
  @media (max-width: 1499px) {
    max-width: unset;
  }
  @media (max-width: 375px) {
    width: 158px;
    height: 94px;
    img {
      width: 60%;
    }
  }
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;

  @media (max-width: 650px) {
    font-size: ${typography.size.l4}px !important;
    font-weight: ${typography.weight.regular1};
    line-height: 62px !important;
    width: auto;
  }
`;
const Description = styled(Text)`
  div {
    width: 78%;
    @media (max-width: 1024px) {
      width: 98%;
    }
  }
`;
const BoxDescription = styled(Text)`
  width: 40%;
  @media (max-width: 1024px) {
    width: auto;
    padding: 30px 0px;
  }
`;
