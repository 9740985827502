import React, { useState, useCallback } from "react";
import ReactBnbGallery from "react-bnb-gallery";
import { Grid, Cell } from "styled-css-grid";
import { useImage } from "react-image";
import placeholder from "../../images/thumb-placeholder.png";
import styled from "@emotion/styled-base";
import "react-bnb-gallery/dist/style.css";

function SafeImage({ photo, onClick }) {
  const { src } = useImage({
    srcList: [photo, placeholder],
    useSuspense: false,
  });

  return <Image src={src} onClick={onClick} />;
}

export function ImageGallery({ photos }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback(
    (event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    },
    [setCurrentImage, setViewerIsOpen]
  );

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  if (!photos) return null;
  return typeof window !== "undefined" && window.innerWidth < 376 ? (
    <>
      <BoxImage columns={2} flow="row dense">
        {photos.map((photo, index) => (
          <Cell height={+photo.height} width={+photo.width}>
            <SafeImage
              photo={photo.photo}
              onClick={(e) => openLightbox(e, { index })}
            />
          </Cell>
        ))}
      </BoxImage>
      <ReactBnbGallery
        show={viewerIsOpen}
        activePhotoIndex={currentImage}
        photos={photos}
        onClose={() => closeLightbox()}
      />
    </>
  ) : (
    <>
      <BoxImage columns={5} flow="row dense">
        {photos.map((photo, index) => (
          <Cell height={+photo.height} width={+photo.width}>
            <SafeImage
              photo={photo.photo}
              onClick={(e) => openLightbox(e, { index })}
            />
          </Cell>
        ))}
      </BoxImage>
      <ReactBnbGallery
        show={viewerIsOpen}
        activePhotoIndex={currentImage}
        photos={photos}
        onClose={() => closeLightbox()}
      />
    </>
  );
}
const BoxImage = styled(Grid)`
  grid-gap: 9px;
  div {
    max-height: 240px;
    height: calc(100vw / 6);
    :first-child {
      height: calc(100vw / 2.94);
      max-height: 489px;
    }
  }
  @media (max-width: 376px) {
    grid-gap: 5px;
    div {
      max-height: 165px;
      height: auto;
      :first-child {
        height: auto;
        max-height: 222px;
      }
    }
  }
`;
const Image = styled("img")`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
