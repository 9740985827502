import React, { useMemo } from "react";
import styled from "styled-components";
import htmlSerializer from "../SpaceConntainer/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import logo from "../../images/logo-small.svg";
import placeholder from "../../images/thumb-placeholder.png";
import { color, typography, spacing } from "../../shared/style";
import { Text } from "../../components/Text/Text";
import { Button } from "../../components/Button/Button";
import { navigate } from "gatsby";
import { ImageGallery } from "./ImageGallery";

const gridLayout = [
  {
    photo: placeholder,
    width: 3,
    height: 2,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
];
const gridLayoutMobile = [
  {
    photo: placeholder,
    width: 2,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
  {
    photo: placeholder,
    width: 1,
    height: 1,
  },
];

export const House = ({ photos, total }) => {
  const items = useMemo(() => {
    if (typeof window !== "undefined" && window.innerWidth < 376) {
      return gridLayoutMobile.map((grid, index) => ({
        ...grid,
        photo: photos[index]?.media_type === "VIDEO" ? photos[index]?.thumbnail_url : photos[index]?.media_url ?? grid.photo,
        thumbnail: photos[index]?.media_url ?? grid.photo,
        number: index,
        subcaption: "",
        caption: photos[index]?.caption ?? grid.photo ?? "",
      }));
    } else {
      return gridLayout.map((grid, index) => ({
        ...grid,
        photo: photos[index]?.media_type === "VIDEO" ? photos[index]?.thumbnail_url : photos[index]?.media_url ?? grid.photo,
        thumbnail: photos[index]?.media_url ?? grid.photo,
        number: index,
        subcaption: "",
        caption: photos[index]?.caption ?? grid.photo ?? "",
      }));
    }
  }, [photos]);

  const primary = useMemo(() => {
    return {
      logo: logo,
      title: {
        raw: [
          {
            type: "preformatted",
            text: `Draper Startup House`,
            spans: [],
          },
        ],
      },
      description: {
        raw: [
          {
            type: "preformatted",
            text: `${total} posts`,
            spans: [],
          },
        ],
      },
      btn: {
        raw: [
          {
            type: "preformatted",
            text: `See More`,
            spans: [],
          },
        ],
      },
    };
  }, [total]);
  const redirectPage = (url) => {
    navigate(`${url}`);
  };
  if (!photos) return null;
  return (
    <WrapperSection>
      <WrapperGeneral>
        <Content>
          <ImageLogo src={primary.logo} />
          <BoxRight>
            <Title
              font={typography.type.code}
              color={color.netraul.black100}
              capital="uppercase"
              size={typography.size.l1}
              weight={typography.weight.regular1}
              lineHeight={36}
              align="left"
            >
              <RichText
                render={primary?.title?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Title>
            <Description
              font={typography.type.primary}
              color={color.netraul.black80}
              size={typography.size.s3}
              weight={typography.weight.regular}
              lineHeight={26}
              align="left"
            >
              <RichText
                render={primary?.description?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Description>
          </BoxRight>
        </Content>
        <GroupBoxImage>
          <ImageGallery photos={items} />
        </GroupBoxImage>
        <BoxFooter>
          <ButtonExtended type="primary" onClick={() => redirectPage("/")}>
            <Text
              font={typography.type.primary}
              size={typography.size.s3}
              weight={typography.weight.bold}
              lineHeight={26}
            >
              <RichText
                render={primary?.btn?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Text>
          </ButtonExtended>
        </BoxFooter>
      </WrapperGeneral>
    </WrapperSection>
  );
};
const WrapperSection = styled.div`
  background: ${color.primary.white};
  max-width: 1500px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  white-space: nowrap;
  background: transparent;
  margin-top: 32px;
  color: ${color.netraul.black100};
  padding: 20px 45px;
  text-transform: capitalize;
  @media (max-width: 650px) {
    margin-top: 40px;
  }
`;
const WrapperGeneral = styled.div`
  padding: 42px 131px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  @media (max-width: 1499px) {
    padding: 42px 40px;
  }
  @media (max-width: 1024px) {
    padding: 80px 30px;
  }
  @media (max-width: 650px) {
    margin: 0;
    padding: 80px 20px;
  }
`;
const Content = styled.div`
  display: flex;
  padding-bottom: 34px;
  align-items: center;
  @media (max-width: 650px) {
    padding-bottom: 30px;
  }
`;
const Description = styled(Text)`
  letter-spacing: -0.015em;
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  @media (max-width: 650px) {
    font-size: 30px !important;
  }
`;
const BoxRight = styled.div``;
const BoxFooter = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
  }
`;
const ImageLogo = styled.img`
  padding-right: 22px;
  @media (max-width: 650px) {
    padding-right: 16px;
  }
`;
const GroupBoxImage = styled.div`
  padding-bottom: 130px;
  @media (max-width: 1024px) {
    padding-bottom: 0;
  }
`;
