import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
// import { navigate } from "gatsby";
import { redirectPage } from "../../hooks/redirect";
// import avatar from "../../images/about-team-avatar-1.png";
import linkedin_default from "../../images/linkedin-default.svg";
import linkedin_active from "../../images/linkedin-icon-active.svg";
import { color, spacing, typography } from "../../shared/style";

export const Member = ({ data }) => {
  // const redirectPage = (url) => {
  //   navigate(`${url}`);
  // };
  const { items } = data || {};
  const [showMore, setShowMore] = useState(true);
  const [listShow, setListShow] = useState([]);
  const [index, setIndex] = useState(4);

  const loadMore = () => {
    let newIndex = index + 4;
    const newShowMore = newIndex < items?.length;
    if (!newShowMore) {
      newIndex = items.length;
    }
    setIndex(newIndex);
    setListShow(items && items?.slice(0, newIndex));
    setShowMore(newShowMore);
  };

  useEffect(() => {
    const flag = [...items];
    if (typeof window !== "undefined" && window.innerWidth < 650) {
      setListShow(flag.slice(0, 4));
    } else {
      setListShow(items);
    }
  }, [items]);
  if (!items) return null;
  return (
    <WrapperSection>
      <WrapperGeneral>
        {listShow?.length !== 0 &&
          listShow?.map((item, i) => {
            const ite = item?.team_member?.document?.data;
            const linkedin = ite?.contacts.find(
              (item) => item.contact_label === "linkedin"
            );
            return (
              <Content key={i}>
                <BoxImage>
                  <Image src={ite?.avatar?.url} />
                  {linkedin && (
                    <ButtonIcon
                      onClick={() =>
                        redirectPage({
                          url: linkedin?.contact_link?.url,
                          type: linkedin?.contact_link?.type,
                          link_type: linkedin?.contact_link?.link_type,
                        })
                      }
                      // type="transparent"
                      icon_default={linkedin_default}
                      icon_active={linkedin_active}
                    ></ButtonIcon>
                  )}
                </BoxImage>
                <Name
                  font={typography.type.code}
                  tag="h3"
                  color={color.netraul.black100}
                  capital="uppercase"
                  size={typography.size.l1}
                  weight={typography.weight.regular1}
                  lineHeight={36}
                  align="left"
                  className="itemName"
                >
                  {ite?.name?.text}
                </Name>
                <Position
                  font={typography.type.primary}
                  color={color.netraul.black60}
                  size={typography.size.s2}
                  weight={typography.weight.regular}
                  lineHeight={22}
                  align="left"
                >
                  {ite?.position?.text}
                </Position>
              </Content>
            );
          })}
      </WrapperGeneral>
      {showMore && (
        <BoxFooter>
          <ButtonExtended
            onClick={loadMore}
            type="primary"
            size={16}
            lineHeight={26}
          >
            <Text
              font={typography.type.primary}
              size={typography.size.s3}
              weight={typography.weight.bold}
              lineHeight={26}
            >
              See more
            </Text>
          </ButtonExtended>
        </BoxFooter>
      )}
    </WrapperSection>
  );
};
const WrapperSection = styled.div`
  background: ${color.primary.white};
  padding-bottom: 90px;
  @media (max-width: 650px) {
    padding-bottom: 80px;
  }
`;
const BoxFooter = styled.div`
  display: none;
  @media (max-width: 650px) {
    display: flex;
    justify-content: center;
  }
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  height: 65px;
  white-space: nowrap;
  background: transparent;
  margin-top: 32px;
  color: ${color.netraul.black100};
  padding: 20px 45px;
  text-transform: capitalize;
  span {
    cursor: pointer;
  }
  @media (max-width: 650px) {
    margin-top: 40px;
  }
`;
const ButtonIcon = styled.button`
  position: absolute;
  cursor: pointer;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 0px;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  align-items: center;
  border: none;
  justify-content: center;
  background-image: url(${(props) => props.icon_active && props.icon_active});
  background-repeat: no-repeat;
  background-position: center center;
  @media (max-width: 650px) {
    visibility: visible;
    opacity: 1;
    height: 32px;
    width: 32px;
    right: 10px;
    bottom: 10px;
  }
  /* &:hover {
    background-image: url(${(props) => props.icon_active && props.icon_active});
    background-repeat: no-repeat;
    transform: scale(1.1);
    transition: transform 0.5s;
    border: none;
  } */
`;
const WrapperGeneral = styled.div`
  max-width: 1240px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-column-gap: 19px;
  grid-row-gap: 24px;
  grid-column-gap: 27px;
  grid-row-gap: 72px;
  /* width: 81%; */
  margin: 0 auto;
  @media (max-width: 1499px) {
    /* width: 91%; */
    max-width: unset;
    margin: 0 40px;
    padding-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 19px;
    grid-row-gap: 24px;
  }
  @media (max-width: 992px) {
    margin: 0 30px;
  }
  @media (max-width: 650px) {
    /* width: 90%; */
    padding-top: 80px;
    margin: 0 20px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 19px;
    grid-row-gap: 24px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer !important;
  &:hover {
    .itemName {
      cursor: pointer;
      color: ${color.primary.blue};
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const Name = styled(Text)`
  transition: all 0.4s ease;
  padding: 24px 0 8px;
  @media (max-width: 650px) {
    padding: 14px 0 8px;
    font-size: 30px !important;
  }
`;
const Position = styled(Text)``;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const BoxImage = styled.div`
  position: relative;
  @media (min-width: 1400px) {
    height: 224px;
    width: 224px;
  }
  @media (max-width: 1400px) {
    max-width: unset;
  }
  max-height: 224px;
  max-width: 224px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  &:hover {
    button {
      visibility: visible;
      opacity: 1;
    }
  }
  @media (max-width: 375px) {
    max-height: 158px;
    max-width: 158px;
    width: 158px;
    height: 158px;
  }
`;
