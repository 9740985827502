import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";

export const OurGoal = ({ data }) => {
  if (!data) return null;
  return (
    <>
      <WrapperSection id="mission">
        <WrapperGeneral>
          <Content>
            {/* <Title
              font={typography.type.code}
              tag="h3"
              color={color.primary.white}
              capital="uppercase"
              size={typography.size.l3}
              weight={typography.weight.regular1}
              lineHeight={56}
              align="left"
            >
              <RichText
                render={data?.title?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Title> */}
            <ImageTitle src={data?.image_title?.url}></ImageTitle>
            <Description
              font={typography.type.primary}
              color={color.primary.white}
              size={typography.size.s3}
              weight={typography.weight.regular}
              lineHeight={26}
              align="left"
            >
              <RichText
                render={data?.description?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Description>
          </Content>
        </WrapperGeneral>
      </WrapperSection>
      <WrapperSectionImage>
        <WrapperImage>
          <Image src={data?.image?.url}></Image>
        </WrapperImage>
      </WrapperSectionImage>
    </>
  );
};
const WrapperSectionImage = styled.div`
  max-width: 1500px;
  width: 100%;
  padding: 0 131px;
  margin: auto;
  box-sizing: border-box;
  background: linear-gradient(
    to bottom,
    ${color.primary.blue} 170px,
    #e9ecf1 0px
  );
  @media (max-width: 1499px) {
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
    background: linear-gradient(
      to bottom,
      ${color.primary.blue} 210px,
      #e9ecf1 0px
    );
  }
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const ImageTitle = styled.img`
  max-height: 165px;
  max-width: 325px;
  @media (max-width: 650px) {
    max-width: 236px;
  }
`;

const WrapperImage = styled.div`
  width: 100%;
  height: 100%;
  height: 654px;
  max-width: 1238px;
  @media (max-width: 1499px) {
    height: 500px;
    max-width: unset;
  }
  @media (max-width: 992px) {
    height: 400px;
  }

  @media (max-width: 650px) {
    height: 268px;
  }
`;

const WrapperSection = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1500px;
  margin: 0 auto;
  /* padding: 0 131px; */
  background: ${color.primary.blue};
  @media (max-width: 1499px) {
    padding: 0 40px;
  }
  @media (max-width: 650px) {
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
const WrapperGeneral = styled.div`
  position: relative;
  padding: 0 131px 50px 228px;
  @media (max-width: 1499px) and (min-width: 1025px) {
    padding: 0 100px;
    padding-bottom: 40px;
  }
  @media (max-width: 1024px) {
    padding: 0;
    padding-bottom: 40px;
  }
  @media (max-width: 650px) {
    padding: 0;
    padding-bottom: 40px;
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 80px;
    align-items: center;
  }
`;
// const Title = styled(Text)`
//   text-align: left;
//   width: 40%;
//   letter-spacing: -0.015em;

//   @media (max-width: 1000px) {
//     width: 50%;
//   }
//   @media (max-width: 768px) {
//     width: 100%;
//     line-height: 36px !important;
//     font-size: 36px !important;
//   }
// `;

const Description = styled(Text)`
  width: 48%;
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 30px;
  }
`;
