import React from "react";
import styled from "styled-components";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
// import handshake from "../../images/handshake.svg";
// import hourglass from "../../images/hourglass.svg";
// import compass from "../../images/compass.svg";
// import productive from "../../images/productive.svg";
// import teamwork from "../../images/teamwork.svg";
import { color, typography } from "../../shared/style";
import { Text } from "../../components/Text/Text";

export const Values = ({ data }) => {
  const { primary, items } = data;
  if (!primary || !items) return null;
  return (
    <WrapperSection>
      <WrapperGeneral>
        <Content>
          <Title
            font={typography.type.code}
            color={color.primary.blue}
            capital="uppercase"
            size={typography.size.l1}
            weight={typography.weight.regular1}
            lineHeight={36}
            align="left"
          >
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          <Description
            font={typography.type.code}
            capital="uppercase"
            color={color.primary.blue}
            size={typography.size.code}
            weight={typography.weight.regular1}
            lineHeight={90}
            align="left"
          >
            <RichText
              render={primary?.description?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Description>
        </Content>
        <List>
          {items?.map((item, i) => {
            return (
              <ItemList key={i}>
                <HeaderItem>
                  <Icon src={item?.icon?.url} />
                </HeaderItem>
                <DescriptionRight
                  size={typography.size.l1}
                  weight={typography.weight.regular1}
                  lineHeight={36}
                  capital="uppercase"
                  font={typography.type.code}
                  color={color.netraul.black90}
                >
                  {/* <RichText render={item?.description?.raw} htmlSerializer={htmlSerializer}/> */}
                  {typeof window !== "undefined" && window.innerWidth > 1000
                    ? item?.description?.text.replace(", ", ",\n")
                    : item?.description?.text}
                </DescriptionRight>
              </ItemList>
            );
          })}
        </List>
      </WrapperGeneral>
    </WrapperSection>
  );
};
const WrapperSection = styled.div`
  max-width: 1500px;
  width: 100%;
  padding: 150px 131px;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${color.primary.blue10};
  @media (max-width: 1499px) {
    padding: 120px 40px;
  }
  @media (max-width: 992px) {
    padding: 100px 30px;
  }
  @media (max-width: 650px) {
    padding: 60px 20px;
  }
`;
const WrapperGeneral = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1024px) {
  }
  @media (max-width: 1000px) {
    flex-direction: column;

    height: auto;
  }
  @media (max-width: 650px) {
    padding: 0;
  }
`;
const List = styled.ul`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: auto auto auto;
  list-style: none;
  @media (max-width: 1200px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 1000px) {
    grid-gap: 10px;
    grid-template-columns: auto;
    padding-top: 40px;
  }
`;
const ItemList = styled.li`
  width: 240px;
  height: 208px;
  /* span {
    white-space: normal;
  } */
  background-color: ${color.primary.blue05};
  @media (max-width: 1000px) {
    margin: 0 auto;
    height: fit-content;
    width: 100%;
    display: flex;
  }
`;
const Icon = styled.img`
  height: 100%;
  width: 100%;
  max-width: 40px;
  max-height: 40px;
`;
const HeaderItem = styled(Text)`
  display: flex;
  padding: 24px 24px 30px;
  @media (max-width: 650px) {
    align-items: center;
    justify-content: center;
    padding: 22px 30px 22px 22px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;
`;
const Description = styled(Text)`
  letter-spacing: -0.015em;
  margin-top: 8px;
  span {
    white-space: normal;
  }
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`;
const DescriptionRight = styled(Text)`
  letter-spacing: -0.015em;
  padding: 0 24px;
  display: block;
  /* white-space: unset !important; */
  @media (max-width: 1000px) {
    display: flex;
    align-items: center;
  }
  @media (max-width: 650px) {
    padding: 0;
    margin: auto 0;
    font-size: 30px !important;
  }
`;
