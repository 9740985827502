import React from "react";
import styled from "styled-components";
import { color } from "../../shared/style";

const Modal = ({ children, open, handleOpenModal, ...props }) => {

  return (
    <WrapperModal display={open ? "block" : "none"}>
      <ModalContent>
        <Overlay onClick={handleOpenModal}></Overlay>
        {/* <MainContent>
          {children}
        </MainContent> */}
        {children}
      </ModalContent>
    </WrapperModal>
  );
};

export default Modal

const WrapperModal = styled.div`
  display: ${(props) => props.display};
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;
const ModalContent = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
`;
const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${color.netraul.black100};
  opacity: 0.9;
  z-index: 99;
  cursor: pointer;
`;
// const MainContent = styled.div`
//   display: flex;
//   width: fit-content;
//   height: fit-content;
//   position: absolute;
//   z-index: 100;
//   background-color: white;
//   padding: 40px;
// `;