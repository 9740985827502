import React from "react";
import styled from "styled-components";
import htmlSerializer from "../SpaceConntainer/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import facebook from "../../images/facebook.svg";
import linkedin from "../../images/linkedin.svg";
import youtube from "../../images/youtube.svg";
import instagram from "../../images/instagram.svg";
import { color, typography } from "../../shared/style";
import { Text } from "../../components/Text/Text";
import { redirectPage } from "../../hooks/redirect";

const items = [
  {
    icon: facebook,
    description: "Facebook",
  },
  {
    icon: linkedin,
    description: "Linkedin",
  },
  {
    icon: youtube,
    description: "Youtube",
  },
  {
    icon: instagram,
    description: "Instagram",
  },
];

export const Social = ({ data }) => {
  const { primary } = data;
  if (!primary) return null;
  return (
    <WrapperSection>
      <WrapperGeneral>
        <wrapperContent>
          <Content>
            <Title
              font={typography.type.code}
              tag="h3"
              capital="uppercase"
              color={color.primary.blue}
              size={typography.size.code}
              weight={typography.weight.regular1}
              lineHeight={90}
              align="left"
            >
              <RichText
                render={primary?.title?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Title>
          </Content>
        </wrapperContent>
        <List>
          {items?.map((item) => {
            return (
              <ItemList
                onClick={() => {
                  const link_name = primary[item.description.toLowerCase()];
                  redirectPage({
                    url: link_name?.url,
                    type: link_name?.type,
                    link_type: link_name?.link_type,
                  });
                }}
              >
                <HeaderItem>
                  <Icon src={item?.icon} />
                </HeaderItem>
                <DescriptionRight
                  size={typography.size.m1}
                  weight={typography.weight.bold}
                  lineHeight={30}
                  font={typography.type.primary}
                  color={color.netraul.black100}
                >
                  {item?.description}
                </DescriptionRight>
              </ItemList>
            );
          })}
        </List>
      </WrapperGeneral>
    </WrapperSection>
  );
};
const WrapperSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  width: 100%;
  background: ${color.primary.white};
  margin-bottom: 40px;
  @media (max-width: 1200px) {
    /* padding-bottom: 100px; */
  }
  @media (max-width: 650px) {
    height: auto;
    /* padding-bottom: 80px; */
  }
`;
const WrapperGeneral = styled.div`
  padding-top: 120px;
  /* width: 81%; */
  max-width: 1240px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1499px) {
    padding: 120px 40px 0;
    column-gap: 10%;
  }
  @media (max-width: 1024px) {
    padding-top: 80px;
    height: auto;
    width: 91%;
    column-gap: 0;
    flex-direction: column;
  }
  @media (max-width: 992px) {
    padding: 120px 30px 0;
    width: 100%;
  }
  @media (max-width: 650px) {
    padding: 80px 20px 0;
  }
`;
const List = styled.ul`
  display: grid;
  grid-gap: 32px;
  height: fit-content;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  list-style: none;
  width: 50%;
  @media (max-width: 1440px) {
    width: 58%;
  }
  @media (max-width: 1366px) {
    width: 65%;
  }
  @media (max-width: 1160px) {
    width: 100%;
  }
  @media (max-width: 1024px) and (min-width: 376px) {
    grid-gap: 32px;
    width: 100%;
  }
  @media (max-width: 376px) {
    grid-row-gap: 20px;
    grid-column-gap: 19px;
  }
`;
const ItemList = styled.li`
  width: 240px;
  height: 150px;
  span {
    cursor: pointer;
  }
  background-color: ${color.primary.blue05};
  @media (max-width: 1366px) {
    height: auto;
    width: auto;
  }
  @media (max-width: 1024px) {
    margin: 0 auto;
    height: auto;
    width: 100%;
  }
  @media (max-width: 1440px) {
    width: 200px;
  }
`;
const Icon = styled.img`
  height: 100%;
  width: 100%;
  max-width: 40px;
  max-height: 40px;
  @media (max-width: 650px) {
    max-height: 32px;
    max-width: 32px;
  }
`;
const HeaderItem = styled(Text)`
  display: block;
  padding: 32px 44px 20px;
  @media (max-width: 650px) {
    padding: 20px 20px 18px;
  }
`;
const wrapperContent = styled.div``;
const Content = styled.div`
  width: 75%;
  margin-bottom: 40px;
  @media (max-width: 1450px) {
    width: auto;
  }
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  @media (max-width: 1000px) {
    font-size: ${typography.size.l4}px !important;
    line-height: 62px !important;
    text-align: center;
    display: block;
  }
`;
const DescriptionRight = styled(Text)`
  letter-spacing: -0.015em;
  padding: 0 44px 28px;
  display: block;
  @media (max-width: 650px) {
    padding: 0 20px 20px;
  }
`;
