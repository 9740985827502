import React, {useCallback, useState} from "react";
import styled from "styled-components";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import { color, typography, spacing } from "../../shared/style";
import { Text } from "../../components/Text/Text";
import { Button } from "../../components/Button/Button";
// import { redirectPage } from '../../hooks/redirect';
import Modal from "../../components/Modal/modal";
import { SendMessageForm } from "./modal/SendMessageForm"

export const Partner = ({ data }) => {
  const { primary } = data;
  const [openContact, setOpenContact] = useState(false);
  const handleOpenModal = useCallback(() => {
    setOpenContact(!openContact)
  }, [openContact, setOpenContact])
  if (!primary) return null;
  return (
    <WrapperSection>
      <WrapperModal>
            <Modal open={openContact} handleOpenModal={handleOpenModal}>
              <SendMessageForm
                handleOpenModal={handleOpenModal}
                url={process.env.GATSBY_MAILCHIMP_URL}
              />
            </Modal>
          </WrapperModal>
      <WrapperGeneral>
        <Content>
          <Title
            font={typography.type.code}
            tag="h3"
            color={color.primary.blue}
            capital="uppercase"
            size={typography.size.l3}
            weight={typography.weight.regular1}
            lineHeight={56}
            align="left"
          >
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          <BoxRight>
            <Description
              font={typography.type.primary}
              color={color.netraul.black80}
              size={typography.size.s3}
              weight={typography.weight.regular}
              lineHeight={26}
              align="left"
            >
              <RichText
                render={primary?.description?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Description>
            <ContainerButton>
              <ButtonExtended type="primary"
                onClick={handleOpenModal}>
                {primary?.button_label?.text}
              </ButtonExtended>
            </ContainerButton>
          </BoxRight>
        </Content>
      </WrapperGeneral>
    </WrapperSection>
  );
};
const ContainerButton = styled.div`
  @media (max-width: 650px) and (min-width: 376px) {
    text-align: center;
  }
`;
const WrapperModal = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 101;
`
const WrapperSection = styled.div`
  display: flex;
  justify-content: center;
  background: ${color.primary.white};
  max-width: 1500px;
`;
const WrapperGeneral = styled.div`
  /* width: 81%; */
  max-width: 1240px;
  background: ${color.secondary.bgrBlue};
  @media (max-width: 1024px) {
    /* width: 91%; */
  }
  @media (max-width: 650px) {
    margin: 0 20px;
  }
`;
const ButtonExtended = styled(Button)`
  border-radius: ${spacing.borderRadius.Larger}px;
  white-space: nowrap;
  background: transparent;
  margin-top: 32px;
  color: ${color.netraul.black100};
  text-transform: capitalize;
  padding: 20px 45px;
  span {
    cursor: pointer;
  }
  @media (max-width: 650px) {
    margin-top: 24px;
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 60px 90px;
  @media (max-width: 900px) {
    padding: 60px;
  }
  @media (max-width: 650px) {
    padding: 40px 30px;
    flex-direction: column;
  }
`;
const Title = styled(Text)`
  span {
    color: ${color.primary.blue} !important;
  }
  text-align: left;
  width: 25%;
  letter-spacing: -0.015em;
  @media (max-width: 1024px) {
    width: 40%;
  }
  @media (max-width: 650px) {
    width: 100%;
    font-size: ${typography.size.l1}px !important;
    line-height: 36px !important;
    font-weight: ${typography.weight.regular};
  }
`;
const Description = styled(Text)`
  @media (max-width: 650px) {
    div {
      padding-top: 24px;
    }
  }
`;
const BoxRight = styled.div`
  width: 57%;
  @media (max-width: 650px) {
    width: 100%;
  }
`;
