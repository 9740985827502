import React from "react";
import styled from "styled-components";
import banner from "../../images/about-us-banner.png";
import bannerMobile from "../../images/Banner-about-mobile.png";
import { color, typography } from "../../shared/style";
import Fade from "react-reveal/Fade";
import { Text } from "../../components/Text/Text";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
export const Banner = ({ data }) => {
  if (!data) return null;
  return (
    <WrapperSection banner={data?.banner1?.url}>
      <Content>
        <TextExtend>
          <Fade distance="10px" bottom duration={2000}>
            <Text
              font={typography.type.code}
              tag="h3"
              color={color.primary.white}
              capital="uppercase"
              // size={typography.size.code}
              weight={typography.weight.regular1}
              // lineHeight={90}
              align="center"
            >
              <RichText
                render={data?.title?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Text>
          </Fade>
        </TextExtend>
        {/* <TextExtendMobile>
          <Fade distance="10px" bottom duration={2000}>
            <Text
              font={typography.type.code}
              tag="h3"
              color={color.primary.white}
              size={typography.size.l4}
              capital="uppercase"
              weight={typography.weight.regular1}
              lineHeight={62}
              align="center"
            >
              We’re Revolutionizing the Way Entrepreneurs connect together
            </Text>
          </Fade>
        </TextExtendMobile> */}
      </Content>
    </WrapperSection>
  );
};
const TextExtend = styled.div`
  display: block;
  white-space: normal;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  letter-spacing: -0.015em;
  @media (max-width: 650px) {
    /* display: none; */
    white-space: normal;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    letter-spacing: -0.015em;
  }
`;
const WrapperSection = styled.div`
  height: 700px;
  box-sizing: border-box;
  background-image: url(${(props) => (props.banner ? props?.banner : banner)});
  background-size: cover;
  background-position: center;
  width: 100%;
  @media (max-width: 650px) {
    height: auto;
    background-image: url(${(props) =>props.banner ? props?.banner : bannerMobile});
    min-height: 500px;
  }
`;
const Content = styled.div`
  width: 75%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  text-align: center;
  @media (max-width: 650px) {
    width: fit-content;
    padding-top: 100px;
    margin-top: 80px;
    padding-bottom: 134px;
  }
`;
